<template>
    <div class="card activities">
        <div v-for="item in items" :key="item.id" class="activity">
            <div class="activity-date">
                <div>
                    {{ formatDate(item.created_date) }}
                </div>
                <div class="activity-time">
                    {{ formatTime(item.created_date) }}
                </div>
            </div>
            <div class="activity-body">
                <div class="activity-sender">{{ item.sender.sender_name }}</div>
                <div class="activity-message" v-html="item.message.body"></div>
            </div>
        </div>
    </div>
</template>
<script>

import moment from 'moment';

export default {
    name: 'InternalDocumentsActivities',
    props: {
        items: {
            type: Array,
            default: () => [],
        },
        record: Object,
    },
    methods: {
        formatDate(date) {
           return moment(date).format('YYYY/MM/DD');
        },
        formatTime(date) {
           return moment(date).format('HH:mm');
        },
    },
}
</script>

<style scoped lang="scss" src="@/assets/sass/pages/activities/activities.scss"></style>
